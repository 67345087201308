import type { ErrorEvent, EventHint } from "@sentry/types";

import { filters } from "@shared/utils/initSentry/filters";

export const beforeSend = (event: ErrorEvent, hint: EventHint) => {
  for (const filter of filters) {
    const result = filter(event, hint);
    if (!result) return null;
  }

  return event;
};
