import { onError } from "@apollo/client/link/error";
import { addBreadcrumb, captureException, type SeverityLevel } from "@sentry/react";

import { unauthorizedErrorRedirect } from "@helpers/unauthorizedErrorRedirect";
import { turnOffErrorReportingIfLoggedOut } from "@shared/utils/initSentry/filters/turnErrorsOff";

const getErrorLink = (notify?, disableUnauthorizedCheck?: boolean) => {
  return onError(({ graphQLErrors, networkError, response }) => {
    addBreadcrumb({
      category: "apolloOnError",
      data: {
        networkError: JSON.stringify(networkError),
        response: JSON.stringify(response),
        graphQLErrors: JSON.stringify(graphQLErrors),
      },
      level: "info" as SeverityLevel,
    });

    if (networkError && "statusCode" in networkError && networkError.statusCode !== 403) {
      captureException(networkError, { level: "warning" });
    }

    turnOffErrorReportingIfLoggedOut(networkError, response);

    if (graphQLErrors && notify) {
      graphQLErrors.forEach(error => notify({ error }));
    }

    if (!disableUnauthorizedCheck) {
      unauthorizedErrorRedirect(networkError);
    }
  });
};

export { getErrorLink };
