import { batBeacon } from "./batBeacon";
import { chunkLoadError } from "./chunkLoadError";
import { crazyEggErrors } from "./crazyEggErrors";
import { objectMatchingId } from "./objectMatchingId";
import { resizeObserverLoop } from "./resizeObserverLoop";
import { errorsTurnedOffFilter } from "./turnErrorsOff";
import { webkitMessageHandlers } from "./webkitMessageHandlers";

export const filters = [
  batBeacon,
  chunkLoadError,
  crazyEggErrors,
  objectMatchingId,
  resizeObserverLoop,
  errorsTurnedOffFilter,
  webkitMessageHandlers,
];
